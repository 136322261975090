#sim-compare-tab {
  .pdata {
    display: flex;

    span {
      flex: 1;
      text-align: center;
    }
  }
}
