.main-title {
  font-weight: 500;
  font-size: 18px;
  color: @subText;
}

.metric {
  color: @accentText;
  font-weight: 700;
  font-size: 18px;
  margin-left: 1%;
}

.metric-subtext {
  font-size: 12px;
  color: @accentText;
}

.metric-title {
  font-weight: 500;
  font-size: 16px;
  color: @subText;
}

.metric-title-sub {
  font-weight: 450;
  font-size: 15px;
  color: @subText;
}

@media screen and (max-width: 1200px) {
  .metric-title {
    font-size: 14px;
  }

  .metric {
    font-size: 16px;
  }
}

.metric-card-static {
  color: @subText;
  font-size: 1.2em;
}

.metric-card-dynamic {
  color: @accentText;
  font-size: 1.2em;
  font-weight: 500;
}

.metric-section {
  margin: 1%;
  padding: 3%;
  //max-height: 65vh;
}

.demo-viewer-container {
  // max-height:65vh;
}
.schedule-dialog {
  background-color: @darkBackground;
  max-width: 100% !important;
  max-height: 100% !important;
}

.schedule-wrapper {
  background-color: @cardBackground;
  border-radius: 4px;

  .timelines-chart {
    .legend {
      display: none;
    }

    .axises .grp-axis text {
      fill: @accentText;
    }

    .axises .y-axis text {
      fill: @subText;
    }

    rect.series-group {
    }

    .reset-zoom-btn {
      display: none;
    }
  }
}

.step-container {
  border: @grey 0.5px solid;
  border-radius: 10px;
  min-height: 100px;
}

.step-row {
  border-bottom: @grey 0.5px solid;
  position: relative;
}
