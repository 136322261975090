.toggle-btn-active {
  div .v-item--active {
    background-color: darken(@accentColor, 20%) !important;
  }
}

#study-constraints,
#study-constellations,
#study-stations,
.target-set-dialog {
  .v-input--selection-controls {
    margin: 0px;
    padding: 0px;
  }
  .v-input--radio-group--column .v-input--radio-group__input {
    .row:hover {
      background-color: darken(@accentColor, 20%);
    }
  }
}

.form-content-max-height {
  max-height: 35vh;
  min-height: 35vh;
}

.constellation-table,
.sets-table {
  a {
    text-decoration: none;
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }

    tr .hover-btn {
      display: none;
    }

    tr:hover .hover-btn {
      display: inline-flex;
    }
  }
}

#customer-builder {
  .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > #expanded-slot
    > .v-data-table
    > .v-data-table__wrapper
    > table
    > .v-data-table-header {
    background-color: darken(@accentColor, 10%) !important;
    height: 20px !important;
  }
}
