// #mapbox-wrapper{
//   position:relative;
// }

.site-popup {
  min-width: 250px;
  .feature-title {
    background-color: @primaryColor;
  }
  .mapboxgl-popup-tip {
    // border-top-color: @subText;
  }

  .mapboxgl-popup-content {
    background-color: @cardBackground;
    padding: 0px;
  }

  .mapboxgl-popup-close-button {
    height: 20px;
    width: 20px;
    font-size: 1.5em;
    color: @accentColor;
  }

  .attribute-wrapper {
    border: 0.25px solid @subText;
    border-radius: 4px;
    padding: 2px;
    color: @subText;

    .col {
      padding: 4px;
    }

    .attribute-row {
      border-bottom: 0.25px solid @subText;
    }

    .attribute-col {
      border-right: 0.25px solid @subText;
      span {
        font-weight: 600;
      }
    }
  }
}

.site-sets-container {
  width: 85%;
}

.site-sets-table .v-data-table__wrapper {
  border-radius: 4px;
  overflow-x: hidden;
}

.site-set-header {
  border-bottom: @cardBackground 1px solid;
  background-color: darken(@primaryColor, 3%);
}

.site-header {
  background-color: lighten(@primaryColor, 40%);

  .v-label {
    color: @cardBackground;
    font-size: 0.9em !important;
    font-weight: 450;
  }
}

.site-rows {
  border-bottom: darken(@subText, 45%) 0.75px solid;
}

.site-rows:hover {
  background-color: darken(@accentColor, 15%);
}

.sites-containers {
  height: 60vh;
  .scroll {
    overflow-x: hidden;
    height: 90%;
    border: darken(@subText, 45%) 0.2px solid;
    border-radius: 4px;
  }

  .scroll::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  .no-sites {
    height: 100%;
  }
}
