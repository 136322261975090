@primaryColor: #37474f; //blue grey darken 3
@secondaryColor: #78909c; //blue grey lighten 1
@accentColor: #00acc1; //cyan darken 1
@errorColor: #f50057; //pink accent 3
@infoColor: #546e7a; //blue grey darken 1
@successColor: #66bb6a; //green lighten 1
@warningColor: #ffb74d; //orange lighten 2

@primaryLight: lighten(@primaryColor, 30%);

@accentEmph: lighten(@accentColor, 15%);
@accentSub: lighten(@accentColor, 5%);

@cardBackground: #1e1e1e; //vuetify dark theme card default background color
@darkBackground: #121212; //vuetify dark theme default background color

//#2a2a2a

@subText: #d4d4d4;
@accentText: #ffffff;

@grey: dimgrey;

.subText {
  color: @subText;
  font-size: 0.75em !important;
}

.primary-txt {
  color: @primaryColor;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.container.main-container {
  padding: 0;
  max-width: none !important;
}

.container.full-container {
  max-width: none;
}

.accent-a {
  color: @accentColor !important;
}

.secondary-background {
  background-color: darken(@secondaryColor, 13%);
}

.primary-bg {
  background-color: @primaryColor;
}

.card-bg {
  background-color: @cardBackground;
}

.white-txt {
  color: @accentText;
}

.grey-txt {
  color: @subText;
}

.outline {
  border: @subText 0.5px solid;
  border-radius: 4px;
}

.outline-grey {
  border: @grey 0.5px solid;
  border-radius: 4px;
}

.v-card.small-form {
  max-width: 500px;
  margin: 0 auto;
}

.v-card__title h1 {
  font-size: 1.8rem;
}

.v-card.max-card {
  max-width: 1080px;
  margin: 0 auto;
}

table tr.no-border td {
  border-bottom: none !important;
}

table tbody.no-hover > tr {
  background: inherit !important;
}

.max-form {
  .v-input__slot {
    max-width: 500px;
  }
}

.v-menu__content {
  z-index: 10000 !important;
}

.floatr {
  float: right;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.table-export {
  position: absolute;
  padding-left: 10px;
  margin-top: -41px;
}

.table-export-top {
  position: absolute;
  margin-top: 15px;
  padding-left: 10px;
}

.flex-margin {
  > .v-btn {
    margin: 0 5px;
  }
}

.icons.d-flex {
  .v-icon {
    padding: 0 1px;
  }
}

.table-row-selected {
  background-color: @accentColor !important;
  border-color: @accentColor !important;
}

.table-filters {
  .v-text-field__details {
    display: none;
  }

  .col {
    padding: 5px;
  }
}

.v-card__title.dense {
  padding-bottom: 0;
}

.v-data-table__wrapper::-webkit-scrollbar {
  width: 15px;
}

.v-data-table__wrapper::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: @subText;
}

.scroll {
  overflow: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 15px;
}

.scroll::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.scroll::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: @subText;
}

.col-no-gutters-inherit {
  .col {
    margin: 0px !important;
    padding: 0px !important;
  }
}

.col-no-gutters .col {
  margin: 0px !important;
  padding: 0px !important;
}

#cesium-container {
  .cesium-widget-credits {
    display: none;
  }
}

@import "./space/mission/mission.less";
@import "./showroom/showroom.less";
@import "./sites/sites.less";
@import "./scale/scale.less";
